import React, { useRef } from 'react'
import { useSelector } from 'react-redux'
import css from './Popup.module.css'
import { useClickOutside } from '../../helpers/hooks/useClickOutside'

export const ThambnailModal = ({ id, setThumbnail }) => {
    const isFirstRenderClick = useRef(false)
    const products = useSelector((state) => state.products.products)
    const element = products?.find((elem) => elem.id === id)
    const mainContentImg = useRef(null)
    useClickOutside(mainContentImg,()=>{
        if(isFirstRenderClick.current)  setThumbnail(false)
        isFirstRenderClick.current = true
    })

    return (
        <div className={css.overlay}>
            <div className={css.wrapperImage} ref={mainContentImg}>
                <img
                    className={css.image}
                    src={element.image_url}
                    style={{
                        background: element?.shirt_color?.RGB
                            ? element?.shirt_color?.RGB
                            : element?.hat_color?.RGB
                            ? element?.hat_color?.RGB
                            : element?.pin_color?.RGB
                            ? element?.pin_color?.RGB
                            : 'white',
                    }}
                />
                <div
                    className={css.closeBtn}
                    onClick={() => setThumbnail(false)}
                >
                    &#10006;
                </div>
            </div>
        </div>
    )
}

import React, { useState } from 'react'
import Header from '../../components/Header/Header'
import SubHeader from '../../components/SubHeader/SubHeader'
import HeaderInfo from '../../components/HeaderInfo/HeaderInfo'
import OrderHistory from './component/OrderHistory'
import CustomButton from '../../components/CustomButton/CustomButton'
import quickPayment from '../../assets/quickPayment.svg'
import banner from '../../assets/banner.svg'
import { Loader } from '../../components/Loader/Loader'
import './history.scss'
import { useEffect, useRef } from 'react'
import { getOrders } from '../../helpers/crud'
import { setOrderList } from '../../helpers/redux/orders'
import { useDispatch, useSelector } from 'react-redux'

const History = () => {
    const [page, setPage] = useState('history')
    const dispatch = useDispatch()
    const orderList = useSelector((state) => state.orders.orderList)
    const [fetching, setFetching] = useState(true)
    const [loading, setLoading] = useState(false)
    const [currentPage, setCurrentPage] = useState(1)
    const [lastPage, setLastPage] = useState(10)
    const ref = useRef()

    useEffect(() => {
        if (!lastPage) return
        if (fetching) {
            if (currentPage > lastPage) return
            setLoading(true)
            getOrders(currentPage)
                .then((res) => {
                    setLastPage(res.data.meta.last_page)
                    setCurrentPage((prev) => prev + 1)
                    dispatch(setOrderList([...orderList, ...res.data.data]))
                })
                .finally(() => {
                    setLoading(false)
                    setFetching(false)
                })
        }
    }, [fetching])

    useEffect(() => {
        //Clean order history when components not rendering
        return () => {
            dispatch(setOrderList([]))
        }
    }, [])


    useEffect(() => {
        ref?.current?.addEventListener('scroll', scrollHandler)
        return () => ref?.current?.removeEventListener('scroll', scrollHandler)
    }, [ref])

    const scrollHandler = (e) => {
        if (
            e.target.scrollHeight -
            (e.target.scrollTop + ref.current.clientHeight) <
            10
        ) {
            setFetching(true)
        }
    }

    return (
        <div className="history">
            {loading && <Loader loading={loading} />}
            <Header />
            <SubHeader />
            {page === 'history' && (
                <>
                    <HeaderInfo title="Order History"></HeaderInfo>
                    <div className="history-info" ref={ref}>
                        {orderList?.map((elem) => {
                            return <OrderHistory elem={elem} />
                        })}
                    </div>
                </>
            )}
        </div>
    )
}

export default History
